<template lang="pug">
.position-fixed.w-100.h-100.d-flex.justify-content-center.align-items-center.left-0.top-0.z-5
  client-only
    .bg-danger.opacity-25.position-absolute.w-100.h-100
    .bg-white.h-min-content.position-relative.px-4.py-5.text-center.d-flex.flex-column
      template(v-if="!hasSent")
        h5.text-danger.font-weight-medium {{ $t('booking.customer_data_error_screen.title') }}

        AgentComponent
          div Felmeddelande:
          div {{ customerDataError?.data?.message }}

          template(#noAgent)
            .mt-3
              .text-sm {{ subtitle }}

            .d-inline-flex.flex-column.text-left.mt-4
              .d-flex.justify-content-center
                PhoneCountrySelect.w-px-110(
                  :value="phoneCountryCode",
                  autocomplete="off",
                  :dial-code="true",
                  @input="selectPhoneCountry"
                )
                input.px-2.border.position-relative(
                  v-model="phone"
                  placeholder="Telefonnummer"
                  :class="v$.phone.$invalid && !preventInvalidVisible ? 'border-danger' : 'border-gray border-left-0'"
                )
                button.flex-grow.py-2.px-3(
                  :class="[isCharter ? 'bg-orange' : 'bg-blue text-white', { 'opacity-50': v$.phone.$invalid }]"
                  :disabled="v$.phone.$invalid"
                  @click="sendManualBookingRequest"
                ) {{ $t('booking.customer_data_error_screen.sendbutton') }}

      div(v-else)
        h4.mb-1.px-5.text-blue {{ $t('booking.customer_data_error_screen.sent-title') }}
        span {{ $t('booking.customer_data_error_screen.sent-subtitle') }}

      nuxt-link.bg-transparent.text-blue.mt-4.pt-3.underline(
        v-if="goBackPath"
        :to="goBackPath"
      ) {{ $t('booking.customer_data_error_screen.goback') }}
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

defineProps({
  goBackPath: {
    type: String,
    default: null
  }
})

const localeStore = useLocaleStore()

const {
  isCharter,
  locale
} = storeToRefs(localeStore)

const bookStore = useBookStore()
const {
  customerDataError,
  stateObjects
} = storeToRefs(bookStore)

const phoneCountryCode = ref(0)
const selectPhoneCountry = (countryCode) => {
  phoneCountryCode.value = countryCode.dial_code
}

const phone = ref('')

const preventInvalidVisible = ref(true)
const hasSent = ref(false)

const { t } = useI18n()

const v$ = useVuelidate({
  phone: {
    phoneNumberValidator: (value, vm) => phoneNumberValidator(value, vm),
    required
  }
}, { phone, phoneCountryCode })

watch(phone, (value) => {
  if (value && !v$.value.phone.$invalid) {
    preventInvalidVisible.value = false
  }
})

const { data } = await useAsyncData(async () => {
  const { data: isCustomerServiceOpen } = await apiFetch(`/${locale.value}/customer-service/is-open`)

  return {
    isCustomerServiceOpen: isCustomerServiceOpen === '1'
  }
}, { server: false, lazy: true  })
const isCustomerServiceOpen = computed(() => data.value?.isCustomerServiceOpen)

const subtitle = computed(() => isCustomerServiceOpen.value
  ? t('booking.customer_data_error_screen.subtitle-open')
  : t('booking.customer_data_error_screen.subtitle-not-open')
)

const sendManualBookingRequest = async () => {
  preventInvalidVisible.value = false
  if (v$.value.phone.$invalid) {
    return
  }

  await apiFetch(`/${locale.value}/booking/manual-request`, {
    method: 'POST',
    body: {
      phone: phone.value,
      phoneCountryCode: phoneCountryCode.value,
      state: stateObjects.value.pop(),
      fromUrl: window.location.href
    }
  })

  hasSent.value = true
}
</script>